<template>
  <div class="bulk-detail-recipients">
    <BulkDetailRecipientFilters
      @filters-updated="updateFilters"
      @filters-cleared="clearFilters"
      @close="showFilters = false"
      :is-open="showFilters"
      :is-active="hasActiveFilters"
      :values="queryModels"
    />
    <div v-if="!dataWithChecked.length && !loading" class="no-results">
      <span class="no-results-text">
        {{ $t("ogchat.bulk.no_results_found") }}
      </span>
    </div>
    <div class="recipients" v-else>
      <PersonalizedTable
        class="table"
        :row-component="BulkDetailRecipientRow"
        :row-list="dataWithChecked"
        :column-list="columns"
        @colevent="toggleRecipient"
        :show-skeleton="loading"
      />
    </div>
    <IonInfiniteScroll @ionInfinite="onScroll" :disabled="allResultsLoaded">
      <IonInfiniteScrollContent></IonInfiniteScrollContent>
    </IonInfiniteScroll>
  </div>
</template>
<script setup>
import { PersonalizedTable } from "@officeguru/components-vue3";
import api from "@/store/plugins/api";
import { ref, defineProps, defineEmits, computed } from "vue";
import { QueryManager } from "@officeguru/webapp-shared/src/helpers/request/query-manager";
import { RequestManager } from "@officeguru/webapp-shared/src/helpers/request/request-manager";
import { QueryStatus } from "@officeguru/webapp-shared/src/helpers/request/queries/query-status";
import { QueryServices } from "@officeguru/webapp-shared/src/helpers/request/queries/query-services";
import { QuerySearch } from "@officeguru/webapp-shared/src/helpers/request/queries/query-search";
import { QueryPage } from "@officeguru/webapp-shared/src/helpers/request/queries/query-page";
import { QueryOrder } from "@officeguru/webapp-shared/src/helpers/request/queries/query-order";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";
import BulkDetailRecipientRow from "@/views/bulk-messaging/components/bulk-detail-recipient-row.vue";
import BulkDetailRecipientHeaderToggle from "@/views/bulk-messaging/components/bulk-detail-recipient-header-toggle.vue";
import BulkDetailRecipientFilters from "@/views/bulk-messaging/components/bulk-detail-recipient-filters.vue";

const emit = defineEmits(["update:modelValue", "interface"]);

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
});

// conversations
const conversations = ref([]);
const allResultsLoaded = ref(false);

// request
const queryManager = new QueryManager();
const requestManager = new RequestManager(api);
const loading = ref(false);
const queryModels = ref({
  search: new QuerySearch(),
  services: new QueryServices(),
  status: new QueryStatus(),
  page: new QueryPage(),
  order: new QueryOrder(),
});

queryManager.addQueries(Object.values(queryModels.value));

async function request() {
  loading.value = true;
  try {
    const queries = queryManager.getSerializedQueries();
    const mappedQuery = {
      ...queries,
      contract_status: queries.status,
    };
    delete mappedQuery.status;
    const response = await requestManager.request(
      "/partner/conversations/available",
      mappedQuery
    );

    conversations.value = [...response.data];

    if (response.meta.current_page === response.meta.last_page) {
      allResultsLoaded.value = true;
    }
  } finally {
    loading.value = false;
  }
}
request();

// checking
const dataWithChecked = computed(() =>
  conversations.value.map((item) => ({ ...item, checked: props.modelValue }))
);

const allChecked = computed(() => {
  if (loading.value) return false;
  return conversations.value.every((item) =>
    props.modelValue.includes(item.id)
  );
});

function toggleAllRecipients() {
  const payload = allChecked.value
    ? []
    : conversations.value.map((item) => item.id);
  emit("update:modelValue", payload);
}

function toggleRecipient(id) {
  const payload = props.modelValue.includes(id)
    ? props.modelValue.filter((item) => item !== id)
    : [...props.modelValue, id];

  emit("update:modelValue", payload);
}

// filters
const showFilters = ref(false);

const hasActiveFilters = computed(() => {
  return queryManager.hasActiveQueries();
});

const activeFiltersCount = computed(() => {
  let count = 0;
  if (queryModels.value.search.search) count += 1;
  if (queryModels.value.services.services.length) count += 1;
  if (queryModels.value.status.status) count += 1;
  return count;
});

function updateFilters({ search, services, status }) {
  queryModels.value.search.search = search;
  queryModels.value.services.services = services;
  queryModels.value.status.status = status;

  request();
}

function clearFilters() {
  conversations.value = [];
  allResultsLoaded.value = false;
  queryModels.value.search.reset();
  queryModels.value.services.reset();
  queryModels.value.status.reset();

  request();
}

function toggleFilterModal() {
  showFilters.value = !showFilters.value;
}

// interface
emit("interface", {
  updateFilters,
  clearFilters,
  activeFiltersCount,
  toggleFilterModal,
});

// scroll
async function onScroll(event) {
  if (loading.value || allResultsLoaded.value) return;
  queryModels.value.page.page += queryModels.value.page.page ? 1 : 2;
  await request();
  event.target.complete();
}

// table config
const columns = computed(() => {
  return [
    {
      component: BulkDetailRecipientHeaderToggle,
      config: {
        checked: allChecked.value,
        toggle: toggleAllRecipients,
      },
      value: "customer.name",
    },
  ];
});
</script>

<style scoped lang="scss">
@use "@/main" as *;

.bulk-detail-recipients {
  display: flex;
  flex-direction: column;
  flex: 1;
}

:deep(.search-bar__input) {
  border: none !important;
}

.recipients {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 32px;
}

.no-results {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no-results-text {
  @include font-body-reg;
  color: $color-grey-900;
  padding: 0 32px;
}
</style>
