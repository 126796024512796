<template>
  <div class="label-value-pair">
    <span class="label">{{ label }}</span>
    <span class="value">{{ value }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "LabelValuePair",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '-',
    }
  },
})
</script>

<style scoped lang="scss">
@use "@/main" as *;

.label-value-pair {
  display: flex;
  padding: 8px 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.label {
  @include font-body-lg-semi;
  color: $color-grey-900;
}

.value {
  @include font-body-reg;
  color: $color-grey-500;
}
</style>
