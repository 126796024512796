<template>
  <div
    data-cy="cmp-input-checkbox"
    :class="rootClass"
  >
    <label>
      <input
        v-model="checked"
        class="input-checkbox__field"
        type="checkbox"
        :value="checkboxValue"
        :disabled="disabled"
      >
      <div
        v-if="isChecked || indeterminate"
        class="checkmark-container"
      >
        <Icon
          class="checkmark-icon"
          :name="isChecked ? 'checkmark-rounded' : 'dash-rounded'"
          :size="14"
        />
      </div>
      <span
        class="input-checkbox__display"
      ><slot /></span>
    </label>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  compatConfig: {
    MODE: 3,
  },
  name: 'InputCheckbox',
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: [Boolean, Array],
      default: false,
    },
    checkboxValue: {
      type: [Boolean, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
    },
    modern: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isChecked() {
      return this.checkboxValue ? this.checked.includes(this.checkboxValue) : this.checked;
    },
    rootClass() {
      const classes = {
        'input-checkbox--modern': this.modern,
        'input-checkbox': true,
        'checkbox-rounded': this.rounded,
      };

      if (this.indeterminate) {
        return {
          ...classes,
          'input-checkbox--indeterminate': true,
        };
      }

      return {
        ...classes,
        'input-checkbox--checked': this.checkboxValue ? this.checked.includes(this.checkboxValue) : this.checked,
        'input-checkbox--disabled': this.disabled,
      };
    },
    checked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@use "../style/main" as *;

$checked: null;
$disabled: null;
$indeterminate: null;

.input-checkbox {
  font-size: 14px;
  color: $color-grey-500;
  position: relative;

  &--checked {
    $checked: & !global;
  }

  &--disabled {
    color: $color-grey-300;
    $disabled: & !global;
  }

  &--indeterminate {
    $indeterminate: & !global;
  }

  &--modern {

    .input-checkbox__display {
      @include font-body-reg;
      color: $color-grey-900;
      padding-left: 24px + 8px;
      display: flex;
      align-items: flex-start;
    }

    .input-checkbox__display::after,
    .checkmark-container {
      height: 24px;
      width: 24px;
    }
  }
}

.input-checkbox__field {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.checkbox-rounded .input-checkbox__display:after {
  border-radius: 6px !important;
}

.input-checkbox__display {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  display: inline-block;
  height: 33px;
  line-height: 33px;
  user-select: none;

  #{$disabled} & {
    cursor: default;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid $color-grey-300;
    border-radius: 8px;
    top: -1px;
    box-sizing: border-box;
    z-index: 0;

    #{$checked} & {
      background-color: $color-grey-900;
      border: 0;
    }

    #{$disabled} & {
      background-color: $color-grey-100;
      border-color: $color-grey-200;
    }

    #{$indeterminate} & {
      background-color: $color-grey-900;
      border: 0;
    }
  }

  .input-checkbox__field:not(:checked):not(:disabled) + &:hover {

    &:after {
      border: 1px solid $color-grey-300;
    }
  }
}

.checkmark-container {
  position: absolute;
  z-index: 1;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .checkmark-icon {
    pointer-events: none;
    margin-top: -2px;
  }
}
</style>
