import { createI18n } from "vue-i18n";
import da from "./locales/da.json";
import en from "./locales/en.json";
import de from "./locales/de.json";

const i18n = createI18n({
  locale: "da",
  fallbackLocale: "en",
  allowComposition: true,
  messages: {
    da,
    en,
    de,
  },
  silentTranslationWarn: false,
  legacy: false,
});

export { i18n };
