<template>
  <div>
    <ion-modal
      ref="modal"
      :is-open="isOpen"
      cssClass="conversation-list-filters-modal"
      @willDismiss="reset"
    >
      <div class="filter-wrapper">
        <div class="filter-header">
          <Icon
            :name="currentFilterSelector ? 'arrow-left' : 'close'"
            :size="22"
            @click="cancel"
          />
          <span
            class="filter-header-text"
            :class="currentFilterSelector ? 'slide-left' : 'slide-right'"
            :key="currentFilterSelectorLabel"
            >{{ currentFilterSelectorLabel }}</span
          >
          <div></div>
        </div>
        <div class="filter-content">
          <TransitionGroup name="list" tag="div">
            <BulkDetailRecipientFiltersOverview
              v-if="!currentFilterSelector"
              key="overview"
              id="overview"
              class="filter-page"
              @show-filter="currentFilterSelector = $event"
              :contract-status-label="selectedLabels.status"
              :service-label="selectedLabels.services"
              :search="values.search.search"
              @search="searchLocal = $event"
            />
            <ConversationListFiltersSelector
              v-if="currentFilterSelector === 'services'"
              key="services"
              class="filter-page"
              :options="servicesOptions"
              :selection="servicesSelectionLocal"
              @update="servicesSelectionLocal = $event"
            />
            <ConversationListFiltersSelector
              v-if="currentFilterSelector === 'status'"
              key="services"
              class="filter-page"
              :options="contractStatusOptions"
              :selection="contractStatusSelectionLocal"
              @update="contractStatusSelectionLocal = $event"
            />
          </TransitionGroup>
        </div>
        <div class="filter-footer">
          <button
            v-if="currentFilterHasSelection"
            class="clear-button"
            @click="clear"
          >
            {{
              currentFilterSelector
                ? $t("ogchat.clear_filter")
                : $t("ogchat.clear_all_filters")
            }}
          </button>
          <button class="save-button" @click="apply">
            {{ $t("ogchat.save") }}
          </button>
        </div>
      </div>
    </ion-modal>
  </div>
</template>

<script>
import api from "@/store/plugins/api.js";
import Icon from "@officeguru/components-vue3/src/components/Icon.vue";
import BulkDetailRecipientFiltersOverview from "@/views/bulk-messaging/components/bulk-detail-recipient-filters-overview.vue";
import ConversationListFiltersSelector from "@/views/conversation-list/components/conversation-list-filters-selector.vue";
import { IonModal } from "@ionic/vue";

export default {
  name: "ConversationListFilters",
  components: {
    Icon,
    IonModal,
    BulkDetailRecipientFiltersOverview,
    ConversationListFiltersSelector,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      servicesOptions: [],
      servicesSelectionLocal: [], // will contain service ids
      contractStatusOptions: [
        { value: "accepted", label: this.$t('ogchat.contracts.active') },
        { value: "open", label: this.$t('ogchat.contracts.offer_sent') },
      ],
      contractStatusSelectionLocal: null, // will contain status
      searchLocal: "",
      currentFilterSelector: null,
    };
  },
  computed: {
    currentFilterHasSelection() {
      if (this.currentFilterSelector === "services") {
        return this.servicesSelectionLocal.length > 0;
      }
      if (this.currentFilterSelector === "status") {
        return this.contractStatusSelectionLocal !== null;
      }
      return this.activeFilterCount > 0;
    },
    currentFilterSelectorLabel() {
      const labels = {
        status: this.$t("ogchat.contract_status"),
        services: this.$t("ogchat.filter_services"),
      };
      return (
        labels[this.currentFilterSelector] || this.$t("ogchat.filter_recipients")
      );
    },
    savedSelections() {
      return {
        services: this.values.services.services,
        search: this.values.search.search,
        status: this.values.status.status,
      };
    },
    activeFilterCount() {
      let count = 0;
      if (this.servicesSelectionLocal.length > 0) {
        count++;
      }
      if (this.contractStatusSelectionLocal !== null) {
        count++;
      }
      if (this.searchLocal) {
        count++;
      }
      return count;
    },
    selectedLabels() {
      return {
        status: this.contractStatusOptions.find(
          (item) => item.value === this.contractStatusSelectionLocal
        )?.label,
        services: this.getSelectedLabels(
          this.servicesOptions,
          this.servicesSelectionLocal
        ),
      };
    },
  },
  async created() {
    this.fetchOptions();
  },
  watch: {
    values: {
      handler() {
        this.reset();
      },
      deep: true,
    },
  },
  methods: {
    async fetchOptions() {
      const { data: serviceData } = await api.get(
        "partner/services?per_page=9999"
      );
      this.servicesOptions = serviceData.data.map((service) => ({
        value: service.id,
        label: service.name,
      }));
    },
    reset() {
      this.cancel();
      const { status, search, services } = this.savedSelections;
      this.servicesSelectionLocal = services;
      this.contractStatusSelectionLocal = status;
      this.searchLocal = search;
    },
    getSelectedLabels(options, selections) {
      return options
        .filter((item) => selections.includes(item.value))
        .map((item) => item.label)
        .join(", ");
    },
    cancel() {
      if (this.currentFilterSelector) {
        this.currentFilterSelector = null;
      } else {
        this.$emit("close");
        this.$refs.modal.$el.dismiss(null, "cancel");
      }
    },
    apply() {
      this.$emit("filtersUpdated", {
        search: this.searchLocal,
        services: this.servicesSelectionLocal,
        status: this.contractStatusSelectionLocal,
      });
    },
    clear() {
      if (this.currentFilterSelector === "status") {
        return this.$emit("filtersUpdated", {
          ...this.savedSelections,
          status: [],
        });
      }

      if (this.currentFilterSelector === "services") {
        return this.$emit("filtersUpdated", {
          ...this.savedSelections,
          services: [],
        });
      }

      this.$emit("filtersCleared");
    },
  },
  mounted() {
    this.$emit("interface", {
      reload: this.fetchOptions,
    });
  },
};
</script>

<style scoped lang="scss">
@use "@/main" as *;

::part(content) {
  border-radius: 16px 16px 0 0;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey-200;
  padding: 16px;
  border-radius: 16px 16px 0 0;

  &-text {
    @include font-body-lg-semi;
    color: $color-grey-900;
  }
}

.filter-content {
  flex: 1;
  position: relative;
}

.filter-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  max-height: 100%;
}

.filter-footer {
  border-top: 1px solid $color-grey-200;
  display: flex;
  gap: 8px;
  padding: 16px;
}

button {
  @include font-body-med;
  border-radius: 8px;
  padding: 8px 16px;
}

.clear-button {
  background-color: transparent;
  color: $color-grey-700;
}

.save-button {
  flex: 1;
  background-color: $color-brand-600;
  color: white;
}

.select-handled,
.select-contact {
  padding: 16px;
  margin: 16px 0;
}

.active {
  color: $color-brand-600;
}

// will run below the status bar on top on iOS w/o this padding
.conversation-list-filters-modal {
  &.ios {
    padding-top: 50px;
  }
}

// transitions
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from {
  transform: translateX(100%);
}

.list-enter-from:is(#overview) {
  transform: translateX(-100%);
}

.list-leave-to {
  transform: translateX(100%);
}

.list-leave-to:is(#overview) {
  transform: translateX(-100%);
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(40vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(-40vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-left {
  animation: slideLeft 0.3s forwards ease;
}

.slide-right {
  animation: slideRight 0.3s forwards ease;
}
</style>
