<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <NavigationHeader :title="$t('ogchat.my_profile')"
                          back-navigation="ChatList">
        </NavigationHeader>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div style="padding: 16px">
        <div class="base-info-wrapper">
          <AvatarBubble :text="initials" :image="me?.media?.url" />
          <h2 class="headline">{{ me.name }}</h2>
          <span class="organization-name" v-if="organizationName">{{ organizationName }}</span>
        </div>
        <div class="divider" />
        <LabelValuePair :label="$t('ogchat.email')" :value="me.email" />
        <LabelValuePair :label="$t('ogchat.phone')" :value="me.phone" />
        <div class="setting-wrapper" :class="{ 'lang-loading': langLoading }" @click="showChangeLanguageDialogue" v-if="languageSwitchEnabled">
          <LabelValuePair :label="$t('ogchat.language')" :value="langTranslation" />
          <span class="change-link">Change</span>
        </div>
        <div class="divider" />
        <div class="logout-wrapper">
          <button @click="logout" class="logout-button">
            {{ $t('ogchat.logout') }}
          </button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import NavigationHeader from '../components/NavigationHeader.vue';
import LabelValuePair from '../components/LabelValuePair';
import {avatarInitials} from '@officeguru/webapp-shared/src/helpers/avatar-helper.js';
import AvatarBubble from '@officeguru/components-vue3/src/components/Avatar.vue';
import { actionSheetController } from '@ionic/vue';

export default defineComponent({
  components: {
    LabelValuePair,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    NavigationHeader,
    AvatarBubble,
  },
  data() {
    return {
      langLoading: false,
    };
  },
  computed: {
    languageSwitchEnabled() {
      if (!this.$store.getters['split/ready']) return;

      const supportedTypes = ['customer', 'partner'];
      const userType = this.me.type;

      if (!supportedTypes.includes(userType)) {
        console.error('Unknown user type. Disabled language switch.');
        return false;
      }

      const mappedType = userType === 'partner' ? 'vendor' : userType;

      return this.$store.getters['split/canAccess'](`${mappedType}-switch-language`);
    },
    me() {
      return this.$store.getters['users/me'];
    },
    initials() {
      return avatarInitials(this.me.name);
    },
    organizationName() {
      const userType = this.me.type;
      return this.me[userType]?.name;
    },
    langTranslation() {
      if (!this.me.lang) return this.$t('ogchat.lang.da');
      return this.$t('ogchat.lang.' + this.me.lang);
    },
    germanEnabled() {
      return this.$store.getters["split/canAccess"](
          "ogchat_enable_german"
      );
    },
  },
  methods: {
    async logout() {
      this.$router.push({ name: 'Logout', query: { lang: this.me?.lang } });
    },
    async showChangeLanguageDialogue() {
      const buttons = [
        {
          text: this.$t('ogchat.lang.da'),
          data: {
            action: 'da',
          },
        },
        {
          text: this.$t('ogchat.lang.en'),
          data: {
            action: 'en',
          },
        }
      ]

      if (this.germanEnabled) {
        buttons.push({
          text: this.$t('ogchat.lang.de'),
          data: {
            action: 'de',
          },
        });
      }

      const actionSheet = await actionSheetController.create({
        backdropDismiss: true,
        cssClass: 'change-language-dialogue',
        header: this.$t('ogchat.language'),
        buttons,
      });

      await actionSheet.present();

      const { data } = await actionSheet.onDidDismiss();
      const selectedLang = data?.action;

      // user cancelled dialogue
      if (typeof selectedLang !== 'string') return;

      // do not switch lang when selected is already active
      if (this.me.lang === selectedLang) return;

      this.langLoading = true;
      try {
        await this.$store.dispatch('users/patch_lang', {
          lang: selectedLang,
        });
      } finally {
        this.langLoading = false;
      }

      this.$root.$i18n.locale = selectedLang;

      localStorage.setItem('reloadChatList', 'true')
      localStorage.setItem('clearFilters', 'true')
    }
  }
});
</script>


<style scoped lang="scss">
@use "@/main" as *;

.header {
  display: flex;
  justify-content: center;
  @include font-body-lg-semi;
  color: $color-grey-900;
}

.loading-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.avatar-bubble {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font-body-semi;
  color: white;
}

.base-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.headline {
  @include font-h5;
  color: $color-grey-900;
}

.organization-name {
  @include font-body-lg-reg;
  color: $color-grey-900;
}

.divider {
  border-top: 1px solid $color-grey-100;
  margin: 24px 0;
}

.logout-wrapper {
  display: flex;
  justify-content: center;
}

.logout-button {
  border: 1px solid $color-semantic-negative-700;
  color: $color-semantic-negative-700;
  background: white;
  border-radius: 8px;
  padding: 16px 24px;
  width: 100%;

  &:disabled {
    opacity: 0.7;
  }
}

.push-button {
  border: 1px solid $color-grey-900;
  color: $color-grey-900;
  display: flex;
  background: white;
  border-radius: 8px;
  padding: 12px 24px;
  margin: auto;
}

.push-text {
  width: 100%;
  height: 200px;
  padding: 8px;
  border: 1px solid $color-grey-900;
  border-radius: 4px;
}

.setting-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  padding: 8px 0;

  .label-value-pair {
    padding: 0;
  }
}

.change-link {
  @include font-body-lg-med;
  color: $color-grey-900;
  margin-bottom: 1px;
}

.lang-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>

<style lang="scss">
// styling by ion component name does not work when scoped,
// therefore second, unscoped styling block is needed
@use "@/main" as *;

ion-action-sheet.change-language-dialogue {
  .action-sheet-group {
    border-radius: 12px 12px 0 0;

    .action-sheet-title {
      @include font-body-lg-semi;
      color: $color-grey-900;
      text-align: center;
      padding: 12px 0;
      min-height: 0;
    }

    button {
      @include font-body-lg-semi;
      color: $color-grey-900;
      padding: 40px;

      border-top: 1px solid $color-grey-100;
      border-bottom: 1px solid $color-grey-100;
    }
  }
  .sc-ion-action-sheet-ios {
    // we take care of the safe inset already on the page, so we can remove it here
    margin-bottom: 0!important;
  }
}
</style>
