<template>
  <ion-page style="margin-bottom: 0 !important;">
    <ion-content :fullscreen="true">
      <div class="login">
        <div class="logo slide-in">
          <img :src="ogLogo" alt="Officeguru logo" />
        </div>
        <div class="credentials">
          <h1 class="headline">{{ $t('ogchat.welcome') }}</h1>
          <input data-cy="login-email" class="input" v-model="email" type="text" name="user" :placeholder="$t('ogchat.email')" ref="email" style="margin-bottom: 24px"/>
          <input data-cy="login-password" class="input" v-model="password" type="password" name="pass" :placeholder="$t('ogchat.password')" />
          <div class="error-wrapper">
            <span class="error" v-if="failed">{{ $t('ogchat.login_error') }}</span>
          </div>
          <button data-cy="login-submit" class="button button-login" @click="authenticate" :disabled="loading"><span>{{ $t('ogchat.login') }}</span></button>
          <a :href="registrationLink" target="_blank" rel="noopener" style="text-decoration: none; width: 100%;">
            <button class="button button-open-profile" type="outline"><span>{{ $t('ogchat.open_profile') }}</span></button>
          </a>
          <a :href="passwordResetLink" target="_blank" rel="noopener" class="forgot-password-link">{{ $t('ogchat.forgot_password') }}</a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import ogLogo from '@/assets/images/og-log.svg';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
  },
  data() {
    return {
      ogLogo,
      email: '',
      password: '',
      failed: false,
      loading: false,
    }
  },
  async beforeMount() {
    await this.$store.dispatch('settings/setup');
  },
  computed: {
    passwordResetLink() {
      if (!process.env.VUE_APP_HUB_URL) {
        throw new Error('process.env.VUE_APP_HUB_URL needs to be defined');
      }
      return process.env.VUE_APP_HUB_URL + 'password/reset';
    },
    registrationLink() {
      if (!process.env.VUE_APP_HUB_URL) {
        throw new Error('process.env.VUE_APP_HUB_URL needs to be defined');
      }
      return process.env.VUE_APP_HUB_URL + 'register';
    },
  },
  ionViewWillEnter() {
    this.loading = false;
    this.failed = false;
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$refs.email?.focus();
    }, 1200)
  },
  watch: {
    email() {
      this.failed = false;
    },
    password() {
      this.failed = false;
    }
  },
  methods: {
    async authenticate() {
      try {
        this.loading = true;
        await this.$store.dispatch('auth/login', { email: this.email, password: this.password });
      } catch(e) {
        this.loading = false;
        this.failed = true;
        throw new Error('Authentication failed', e);
      }

      this.$router.push({ name: 'ChatList' });
    }
  }
});
</script>

<style scoped lang="scss">
@use "@/main" as *;

@keyframes slideIn {
  0% {
    margin-top: 64px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.slide-in {
  animation-name: slideIn;
  animation-duration: 1s;
}

.slide-in-reverse {
  animation-name: slideIn;
  animation-duration: 1s;
  animation-direction: reverse;
}

.headline {
  @include font-h4;
  color: white;
  margin-bottom: 16px;
  margin-top: 64px;
  align-self: flex-start;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(315deg, #1C1D37 0%, #25274F 100%);
  min-height: 100vh;
  align-items: center;
  padding: 32px;
}

.logo {
  display: flex;
  flex: 0 1 33%;
  max-height: 200px;
  justify-content: center;
  align-items: center;
}

.credentials {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.button {
  @include font-body-semi;
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  height: 50px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
  }

  &-login {
    color: white;
    background: $color-grey-900;
  }

  &-open-profile {
    color: $color-grey-300;
    background: transparent;
    border: 1px solid $color-grey-300;
  }
}

.forgot-password-link {
  @include font-body-semi;
  color: $color-grey-300;
  margin-top: 20px;
}

.input {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 8px;
  border: 0;
  box-shadow: none;
  padding: 16px;
  width: 100%;
}

.error-wrapper {
  text-align: center;
  margin-top: 32px;
}

.error {
  color: $color-semantic-negative-600;
}

.input::placeholder {
  color: $color-grey-300;
}
</style>
