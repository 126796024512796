<template>
  <ion-page>
    <ion-header :translucent="true" v-if="conversation">
      <ion-toolbar>
        <NavigationHeader
          back-navigation="ChatList"
          :title="conversationPartner"
          :sub-title="conversationTopic"
        >
          <template #right>
            <div class="show-task-container" v-if="hasTask" @click="openExternalTask">
              <Icon name="external" />
              <div class="show-task-text">{{ $t('ogchat.see_task') }}</div>
            </div>
          </template>
        </NavigationHeader>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" ref="content">
      <LoadingAnimation v-if="!hasLogs && logsLoading" />
      <ConversationMessageArea
          v-else
          :conversation="conversation"
          :logs="logs"
          :logs-loading="logsLoading"
          :can-get-more-logs="canGetMoreLogs"
          @load-more="fetchLogs"
      />
    </ion-content>
    <ion-footer>
      <ConversationHandledBanner v-if="showHandledBanner" :conversation="conversation" />
      <ConversationMessageCompose
          :disabled="logsLoading"
          :conversation="conversation"
          :can-chat="canChat"
          @send="sendMessage"/>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonContent, IonFooter, IonHeader, IonPage, IonToolbar } from '@ionic/vue';
import {defineComponent} from 'vue';
import NavigationHeader from '../../../components/NavigationHeader.vue';
import ConversationHandledBanner from '@/views/conversation-detail/components/conversation-handled-banner.vue';
import { Keyboard } from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';
import ConversationMessageArea from '../components/conversation-message-area.vue';
import ConversationMessageCompose from '../components/conversation-message-compose.vue';
import Icon from '@officeguru/components-vue3/src/components/Icon';
import LoadingAnimation from '@/components/loading/LoadingAnimation.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { Network } from '@capacitor/network';

let logsWatcher;

/*
  All conversations can easily be handled via their conversation id, except for the support conversation. It has
  a special handling (the id is `support`) and because of that, we need to load the `conversation` first, wait
  for it to be finished, take the conversation id from the response and use it to get the conversation-logs. So,
  whenever you need the conversations id think about if you need a string called `support` in case of the support
  conversation (then you can use this.$route.params.id) or if you need the real, encoded conversation id (then
  you should use this.conversation.id but only after it resolved).
 */
export default defineComponent({
  name: 'ChatDetail',
  components: {
    LoadingAnimation,
    Icon,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonFooter,
    NavigationHeader,
    ConversationMessageArea,
    ConversationMessageCompose,
    ConversationHandledBanner,
  },
  async ionViewDidEnter() {
    document.addEventListener('resume', this.onResume);

    if (!this.hasLogsMeta) {
      await this.fetchConversation();
      await this.fetchLogs({ initial: true });
    }

    // registering the watcher via configuration API does not unregister the watcher when leaving the view, therefore
    // we now manually unregister in the `ionViewWillLeave` method
    logsWatcher = this.$watch('logs', async (newValue = [], oldValue = []) => {
      // possible cases
      // - logs are empty (shouldn't happen)
      // - user comes back to a conversation where logs have already been loaded
      if (newValue.length === oldValue.length) {
        await this.goToBottom(0);
        return;
      }

      // possible cases
      // - user opens a conversation the first time
      if (oldValue.length === 0) {
        await this.goToBottom(0);
        this.markAsRead();
        return;
      }

      // possible cases
      // - n > 1 log item(s) got PREPRENDED (user clicked `load more`)
      if (newValue[0]?.id !== oldValue[0]?.id) {
        // @TODO: messages got prepended (load more)
        // This needs to be implemented but I have no idea how to get the
        // inner height of the <ion-content> to know where we were BEFORE appending the messages
        // await this.scrollToOldScrollPosition();
        this.markAsRead();
        return;
      }

      // possible cases
      // - n > 1 log item(s) got APPENDED (via websocket or the app user sent a message)
      if (newValue[0]?.id === oldValue[0]?.id) {
        await this.goToBottom(0);
        this.markAsRead();
      }
    }, { immediate: true })

    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardDidShow', (event) => {
        const keyboardHeight = event.keyboardHeight;
        this.$nextTick(() => {
          this.$refs.content.$el.scrollToBottom(keyboardHeight);
        })
      })
    }
  },
  ionViewWillLeave() {
    document.removeEventListener('resume', this.onResume);
    if (typeof logsWatcher === 'function') logsWatcher();

    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.removeAllListeners();
    }
  },
  computed: {
    showHandledBanner() {
      return this.isHandled && this.isPartner && this.conversationHandlingEnabled;
    },
    conversationHandlingEnabled() {
      return this.$store.getters['split/canAccess'](
          'ogchat-handle-conversations'
      );
    },
    isPartner() {
      return this.me.type === 'partner';
    },
    isHandled() {
      return !!this.conversation.handled?.user;
    },
    canChat() {
      return this.conversation.is_support || this.conversation.can_chat;
    },
    isLoadingSingleConversation() {
      return this.$store.getters['conversations/singleLoading'](this.$route.params.id);
    },
    conversationTopic() {
      if (this.conversation?.task?.service?.name) return this.conversation.task.service.name;
      if (this.conversation?.conversation_name) return this.conversation.conversation_name;
      if (this.conversation?.services) return this.conversation.services.join(', ');
      return '';
    },
    conversationPartner() {
      if (this.me.type === 'partner' && this.conversation?.customer?.name) return this.conversation.customer.name;
      if (this.conversation?.partner?.name) return this.conversation.partner.name;
      if (this.conversation?.conversation_byline) return this.conversation.conversation_byline;
      return '';
    },
    conversation() {
      return this.$store.getters['conversations/single']({ id: this.$route.params.id });
    },
    logs() {
      return this.$store.getters['conversationLogs/logs']({ id: this.conversation.id });
    },
    hasLogs() {
      const logs = this.$store.getters['conversationLogs/logs']({ id: this.conversation.id })
      return Array.isArray(logs) && logs.length > 0;
    },
    hasLogsMeta() {
      return this.$store.getters['conversationLogs/hasLogsMeta']({ id: this.conversation.id });
    },
    logsLoading() {
      return this.$store.getters['conversationLogs/isLoading']({ id: this.conversation.id });
    },
    canGetMoreLogs() {
      return this.$store.getters['conversationLogs/canGetMoreLogs']({ id: this.conversation.id });
    },
    hasTask() {
      return this.conversation && this.conversation.task && this.conversation.task.id;
    },
    me() {
      return this.$store.getters['users/me'];
    },
  },
  methods: {
    onResume() {
      this.fetchConversation();
      this.fetchLogs({ initial: true });
    },
    async goToBottom(duration = 0) {
      if (!(this.$refs.content && this.$refs.content.$el)) return;

      this.$refs.content.$el.scrollToBottom(duration);
    },
    markAsRead() {
      this.$store.dispatch('conversations/read', { id: this.conversation.id, isSupport: this.conversation.is_support });
    },
    async sendMessage({ payload, sentStatusCallback }) {
      // For testing the server error case, a message including the following SHA can be written - this will
      // skip the request to the backend and call the status callback with an error
      if (typeof payload?.text === 'string' && payload.text.includes('9b09d925bac')) {
        setTimeout(() => {
          sentStatusCallback(true);
        }, 2000);
        return;
      }

      try {
        await this.$store.dispatch('conversationLogs/addLog', {
          id: this.conversation.id,
          payload,
          isSupport: this.conversation.is_support,
          retryAmount: 5,
        });
      } catch(e) {
        // Currently logging every single error that happens - this can be improved over time
        // but let's first see what we get here
        bugsnag.notify(e, (event) => {
          event.context = 'OGChat user tried to send a message, but backend responded with non success.';
          event.addMetadata('additional', {
            payload,
            isSupport: this.conversation.is_support,
            id: this.conversation.id,
            error: e,
            user: this.me,
            network_status: Network.getStatus(),
          });
        });

        sentStatusCallback(true);
        return;
      }
      sentStatusCallback();
    },
    openExternalTask() {
      if (this.hasTask) {
        let redirectBaseUrl;
        if (this.me.type === 'partner') {
            redirectBaseUrl = process.env.VUE_APP_PARTNER_APP_URL;
        }
        if (this.me.type === 'customer') {
            redirectBaseUrl = process.env.VUE_APP_CUSTOMER_APP_URL;
        }

        window.open(`${redirectBaseUrl}tasks/${this.conversation.task.id}`)
      }
    },
    fetchConversation() {
      return this.$store.dispatch('conversations/get_single', { id: this.$route.params.id });
    },
    async fetchLogs({ initial = false, resetPage = false } = {}) {
      await this.$store.dispatch('conversationLogs/getLogs', { id: this.conversation.id, isSupport: this.conversation.is_support, resetPage });
      await this.$nextTick();

      if (initial) {
        await this.goToBottom();
      }
    },
  }
});
</script>

<style scoped lang="scss">
@use "@/main" as *;

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.back-button {
  width: 80px;
}

.conversation-title {
  flex: 1;
  text-align: center;
}

.show-task-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: $color-grey-900;
}

.show-task-text {
  @include font-caption-med;
  color: $color-grey-900;
  margin-top: 6px;
}

.message-input {
  flex: 1;
}

.loading-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-grey-900;
  background: $color-grey-100;
  margin: 16px;
  border-radius: 8px;
}

</style>
