<template>
  <div class="header-container">
    <div class="header-top">
      <div class="left-slot-container side-container">
        <slot name="left">
          <div
            v-if="backNavigation"
            @click="goBack"
            class="go-back-arrow"
          >
            <Icon name="arrow-left" :size="34" class="icon" />
          </div>
        </slot>
      </div>

      <div class="title-container">
        <div class="title">{{ title }}</div>
        <div class="subtitle" v-if="subTitle">{{ subTitle }}</div>
      </div>

      <div class="right-slot-container side-container">
        <slot name="right"></slot>
      </div>
    </div>

    <div class="connection-status-warning" :class="{ 'connection-status-warning--show': isOffline }">
      <p class="connection-status-warning-text">{{ $t('ogchat.there_is_no_internet_connection') }}</p>
    </div>
  </div>
</template>

<script>
import Icon from '@officeguru/components-vue3/src/components/Icon.vue';

export default {
  components: {
    Icon
  },
  props: {
    backNavigation: {
      type: String,
      default: null
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    }
  },
  computed: {
    isOffline() {
      return !this.$store.getters['settings/isOnline'];
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: this.backNavigation })
    }
  }
}
</script>

<style scoped lang="scss">

@use "@/main" as *;

.header-container {
  background-color: $color-grey-50;
  width: 100%;
  border-bottom: 1px solid $color-grey-100;
  display: flex;
  flex-direction: column;
}

.header-top {
  height: 72px;
  width: 100%;
  display: flex;
}

.side-container {
  width: 100px;
}

.go-back-arrow {
  font-size: 0;
  cursor: pointer;
  margin-left: -8px;

  .icon {
    color: $color-grey-900;
  }
}

.title-container {
  overflow: hidden;
  flex: 1 0 calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  @include font-body-lg-semi;
  @include overflow-ellipsis;
  color: $color-grey-900;
  text-align: center;
  max-width: 100%;
}

.subtitle {
  @include font-body-reg;
  @include overflow-ellipsis;
  color: $color-grey-900;
  text-align: center;
  max-width: 100%;
}

.right-slot-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 16px;
}

.left-slot-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
}

.connection-status-warning {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 0;
  background: $color-semantic-notice-50;
  opacity: 0;
  transition: height 0.5s, opacity 0.5s;
  overflow: hidden;

  &--show {
    opacity: 1;
    height: 30px;
    transition-delay: 2s;
  }
}

.connection-status-warning-text {
  @include font-caption-semi;
  color: $color-semantic-notice-700;
  margin: 0;
  padding: 0;
}
</style>
