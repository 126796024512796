<template>
  <div
    class="wrapper"
    :class="{ 'table-header-shown': showTableHeader }"
    data-cy="personalized-table-info"
  >
    <div class="inner">
      <img
        v-if="image"
        class="image"
        :src="image"
      >
      <h2 class="headline">
        {{ headline }}
      </h2>
      <p class="subtitle">
        {{ subtitle }}
      </p>
      <ButtonV2
        v-if="actionText"
        class="action-button"
        data-cy="personalized-table-info__action-button"
        type="link"
        @click="action()"
      >
        {{ actionText }}
      </ButtonV2>
    </div>
  </div>
</template>

<script>
import ButtonV2 from '../ButtonV2.vue';

export default {
  name: 'PersonalizedTableInfo',
  components: {
    ButtonV2,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    headline: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    actionText: {
      type: String,
      default: null,
    },
    action: {
      type: Function,
      default: () => {},
    },
    showTableHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@use "../../style/main" as *;

.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 40vh;
  min-height: 300px;
  max-height: 500px;
  align-items: center;

  &.table-header-shown {
    background: $color-grey-25;
    border: 1px solid $color-grey-200;
    border-top: 0;
    border-radius: 0 0 16px 16px;
    height: auto;
  }
}

.inner {
  text-align: center;
  max-width: 40ch;

  * {
    margin-left: auto;
    margin-right: auto;
  }
}

.image {
  margin-bottom: 24px;
}

.headline {
  @include font-h5;
  color: $color-grey-500;
}

.subtitle {
  @include font-body-lg-reg;
  color: $color-grey-500;
}

.headline,
.subtitle {
  margin-bottom: 16px;
}

.action-button {
  margin-top: -8px;
}
</style>
