<template>
  <div
    class="bulk-recipient-row-wrapper"
    :class="{ 'bulk-recipient-row--skeleton': showSkeleton }"
    @click="emit('colevent', item.id)"
  >
    <div v-if="!showSkeleton" class="bulk-recipient-row">
      <div class="checkbox">
        <InputCheckbox
          :checkbox-value="item.id"
          :model-value="item.checked"
          class="recipient-checkbox"
        />
      </div>
      <div class="customer-wrapper">
        <p class="customer-name">{{ item.customer?.name }}</p>
        <p class="location">{{ item.location }}</p>
        <p class="service">
          {{ item.service?.name }}
        </p>
      </div>
      <div>
        <StatusBadge :status="item.contract?.status" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { InputCheckbox } from "@officeguru/components-vue3";
import StatusBadge from "@/views/bulk-messaging/components/status-badge.vue";

const emit = defineEmits(["colevent"]);
defineProps({
  item: {
    type: [Object, Number], // Number when skeleton
    required: true,
  },
  showSkeleton: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
@use "@/main" as *;

p {
  margin: 0;
}

.bulk-recipient-row-wrapper {
  display: flex;
  width: 100%;
  padding: 16px 16px;
  min-height: 60px;
}

.recipient-checkbox {
  pointer-events: none;
}

.bulk-recipient-row {
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: flex-start;

  &__item {
    padding: 16px 0 16px 24px;
  }

  &--skeleton {
    animation: pulse 0.8s linear infinite;
    background: #f3f4f6;
    border-bottom: 1px solid #caccd4;
  }

  @keyframes pulse {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
}

.checkbox {
  align-self: flex-start;
}

:deep(.input-checkbox) {
  height: 24px !important;
}

:deep(.checkmark-container) {
  height: 24px !important;
  width: 24px !important;
}

:deep(.input-checkbox__display::after) {
  height: 24px !important;
  width: 24px;
}

.customer-wrapper {
  flex: 1;
  padding-right: 16px;
  margin-top: -2px;
}

.customer-name {
  @include font-body-semi;
  color: $color-grey-900;
  line-height: 20px;
}

.location {
  @include font-body-reg;
  color: $color-grey-500;
  line-height: 20px;
}

.service {
  @include font-body-reg;
  color: $color-grey-900;
}
</style>
